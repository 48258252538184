import { Photo } from './Photo';
import { Video } from './Video';
import { Model } from './Model';
import { BaseEntity } from './BaseEntity';

export enum BlockType {
  ADVERTISEMENT = 'advertisement',
  MODEL = 'model',
  PHOTO = 'photo',
  VIDEO = 'video',
}

export enum PageType {
  HOME = 'home',
  JOIN = 'join',
  MODEL = 'model',
  NEWS = 'news',
  SITE = 'site',
  VIDEO = 'video',
}

export enum BlockBox {
  YES = 1,
  NO = 0
}

export interface BlockAdvertisement extends BaseEntity {
  blockId: number
  image: string
  imageLink: string
  link: string
  mobileImage: string
  mobileImageLink: string
}

export type BlockVideo = Video & { sort: number };
export type BlockModel = Model & { sort: number };

export interface Block extends BaseEntity {
  title: string
  titleEn: string
  description: string
  type: BlockType
  page: PageType
  isBox: BlockBox
  sort: number
  advertisement: BlockAdvertisement
  photos: Photo[]
  videos: BlockVideo[]
  models: BlockModel[]
  photoIds: number[]
  videoIds: number[]
  modelIds: number[]
}

export type PartialBlockAdvertisement = Partial<BlockAdvertisement>;
export type PartialBlock = Partial<Block>;
